@import "~@fluentui/react/dist/css/fabric.css";
@import "~react-image-gallery/styles/css/image-gallery.css";

html {
  /* scroll-behavior: smooth; */
  height: 100%;
}

body {
  font-size: 16px;
  height: 100%;
}
* {
  box-sizing: border-box !important;
}
